import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import {  API } from "../../App";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Layout from '../../layout/Layout';
import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import { Link } from "react-router-dom";

var Page_array = [];
const CarinfoRcCount = () => {
  const token = Cookies.get("fmljwt");
  const [Data, setData] = useState([])
  const [size, setSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [loading, setloading] = useState(true)
  const [query, setquery] = useState({
    date: "",
    type: ""
  })
  const GetData = async () => {
    const Form = new FormData();
    Form.append('date', query.date)
    Form.append('type', query.type)
    Form.append('page', current)
    Form.append('limit', size)
    const result = await API.post("/carInfocount", Form, { headers: { authorization: `${token}` } })
    setData(result.data)
    PageGetData()
    setloading(false)
  }



  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };


  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  const handleChange = async (e, name) => {
    // if(name==="live"){
    setquery({ ...query, [name]: e });
    // }
    setCurrent(1);
    setSize(10);
  }

  const data = [{ label: "Select Type", value: "" }, { label: "RTO to CarInfo Type", value: 0 }, { label: "CarInfo to RTO Type", value: 1 }]

  useEffect(() => {
    GetData()
  }, [current, size, query])

  return (
    <Layout sidebar={true}>
      <div className="page-heading backbtn">
        <h3 className="my-1">
          <Link to="/rc-exchange-report" className='btn btn-transparent btn-icon me-2'>
            <i className='bx bx-chevron-left' ></i>
          </Link>
          RC Carinfo Count
        </h3>
        <div className="page-heading-right">
          <DateRangePicker
            initialSettings={{ locale: { format: 'DD-MM-YYYY' } }}
            onApply={(e) => { handleChange(e.target.value, "date") }}
            onCancel={(e) => { e.target.name = 'date'; e.target.value = ""; handleChange(e.target.value, "date") }}>
            <input type="text" name="date" value={query.date} placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
          </DateRangePicker>
          <SelectPicker
            cleanable={false}
            data={data}
            onChange={(e) => { handleChange(e, "type") }}
            defaultValue={""}
            className="wv-200 my-1 ms-3"
            placeholder="Select Type"
          />
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            defaultValue={10}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            <Table className="table-bg-primary" striped bordered responsive>
              <thead>
                <tr className="text-center">
                  <th rowSpan={2}>No</th>
                  <th rowSpan={2}>Date</th>
                  <th colSpan={3}>Vender Exchange Report</th>
                </tr>
                <tr className="text-center">
                  <th>Success</th>
                  <th>Fail</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                    return (
                      <tr key={i} className="text-center">
                        <td width="5%"> {(current === 1) ? i + 1 : current * size + i + 1 - size} </td>
                        <td width="17%">{dayjs(val.date).format('DD-MM-YYYY')}</td>
                        <td width="13%">{val.rto_to_carinfo_success}</td>
                        <td width="13%">{val.rto_to_carinfo_fail}</td>
                        <td width="13%">{val.rto_to_carinfo_success + val.rto_to_carinfo_fail}</td>
                      </tr>
                    )
                  }) : <tr>
                    <td colSpan="100%" className="p-0">
                      <div className='no-found'>
                        <img src="../../not-found/image.svg" />
                        <p>No Found Carinfo RC Count</p>
                      </div>
                    </td>
                  </tr>
                }
                {
                  Data?.totalcount?.totalLength != 0 ?
                    <tr className="text-center fw-600">
                      <td colSpan={2}>Total Record Of API Calling</td>
                      <td>{Data?.totalcount?.totalSuccessofrtoTOcarinfo}</td>
                      <td>{Data?.totalcount?.totalofrtoTOcarinfofail}</td>
                      <td>{Data?.totalcount?.GrandtotalofrtotoCarinfoTotal}</td>
                    </tr> : ""
                }
              </tbody>

            </Table>
            {Data?.totalcount?.totalLength > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={Data?.totalcount?.totalLength}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default CarinfoRcCount